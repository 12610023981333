import axios from 'axios'
import moment from 'moment'

let programs = []
getProgram()

async function getProgram() {
    const program = await axios.get(process.env.NODE_ENV == 'production' ? '/api/program' : 'http://127.0.0.1:8000/api/program')
    programs = program.data
}

function changeProgramByType(type) {
    getProgram()
    if (type.includes('IS')) {
        programs = programs.filter((program) => { return program.code == "DTI" | program.code == "IST" | program.code == "NST" | program.code == "ITM" | program.code == "AIBA" })
    } else {
        programs = programs.filter((program) => { return program.code == "IT" | program.code == "DSBA" | program.code == "BIT" })
    }
    return programs
}

function changeProgramName(programs) {
    if (programs == 'IT' | programs == 'DSBA' | programs == 'BIT' | programs == 'DTI' | programs == 'IST' | programs == 'ITM' | programs == 'NST' | programs == 'AIBA') {
        switch (programs) {
            case 'IT':
                programs = "เทคโนโลยีสารสนเทศ"
                break
            case 'DSBA':
                programs = "วิทยาการข้อมูลและการวิเคราะห์เชิงธุรกิจ"
                break
            case 'BIT':
                programs = "เทคโนโลยีสารสนเทศทางธุรกิจ (หลักสูตรนานาชาติ)"
                break
            case 'DTI':
                programs = "เทคโนโลยีและนวัตกรรมข้อมูล"
                break
            case 'IST':
                programs = "เทคโนโลยีระบบสารสนเทศ"
                break
            case 'ITM':
                programs = "เทคโนโลยีสารสนเทศ และการจัดการ"
                break
            case 'NST':
                programs = "เทคโนโลยีเครือข่ายและระบบ"
                break
            case 'AIBA':
                programs = "ปัญญาประดิษฐ์เพื่อการวิเคราะห์เชิงธุรกิจ"
                break
        }
    } else {
        programs.forEach(function(program) {
            switch (program.code) {
                case 'IT':
                    program.name = "เทคโนโลยีสารสนเทศ"
                    break
                case 'DSBA':
                    program.name = "วิทยาการข้อมูลและการวิเคราะห์เชิงธุรกิจ"
                    break
                case 'BIT':
                    program.name = "เทคโนโลยีสารสนเทศทางธุรกิจ (หลักสูตรนานาชาติ)"
                    break
                case 'DTI':
                    program.name = "เทคโนโลยีและนวัตกรรมข้อมูล"
                    break
                case 'IST':
                    program.name = "เทคโนโลยีระบบสารสนเทศ"
                    break
                case 'ITM':
                    program.name = "เทคโนโลยีสารสนเทศ และการจัดการ"
                    break
                case 'NST':
                    program.name = "เทคโนโลยีเครือข่ายและระบบ"
                    break
                case 'AIBA':
                    program.name = "ปัญญาประดิษฐ์เพื่อการวิเคราะห์เชิงธุรกิจ"
                    break
            }
        })
    }

    return programs
}

function changeTypeName(course, type) {
    let type_name = ""
    if (type == 'projectEdit') {
        if (course.type.includes('IS'))
            type_name = "INDEPENDENT STUDY ".concat(course.type_is || course.semester)
        else if (course.type == 'PJ')
            if (course.prev_course == null)
                type_name = "PROJECT ".concat(" 1")
            else
                type_name = "PROJECT ".concat(" 2")
        else if (course.type == 'CP')
            type_name = "COOPERATIVE EDUCATION"
    } else if (type == 'projectView') {
        if (course.type.includes('IS'))
            type_name = "INDEPENDENT STUDY"
        else if (course.type == 'PJ')
            type_name = "PROJECT"
        else if (course.type == 'CP')
            type_name = "COOPERATIVE EDUCATION"
    } else {
        if (course.type.includes('IS'))
            type_name = course.type_name.concat(" ").concat(course.type_is || course.semester)
        else if (course.type == 'PJ')
            if (course.prev_course == null)
                type_name = course.type_name.concat(" 1")
            else
                type_name = course.type_name.concat(" 2")
        else if (course.type == 'CP')
            type_name = course.type_name
    }
    return type_name
}

function enrollList(enrolls, projects, courseId) {
    enrolls.sort(function(s1, s2) { return s1.code - s2.code })
    for (let i = 0; i < enrolls.length; i++) {
        enrolls[i].topic_th = null
        enrolls[i].topic_en = null
        for (let j = 0; j < projects.length; j++) {
            if (projects[j].members.includes(enrolls[i].user_id)) {
                if (projects[j].course == courseId) {
                    enrolls[i].topic_th = projects[j].topic_th
                    enrolls[i].topic_en = projects[j].topic_en
                }
            }
        }
    }
}

function yearCourseSelect(courses) {
    let years = new Array()
    let yearCourses = new Array()
    for (let course in courses) {
        years.push(courses[course]["year"])
    }
    years = [...new Set(years)].sort().reverse()
    for (let year in years) {
        let tmp = []
        let tmp_dict = {}
        for (let course in courses) {
            if (courses[course]["year"] == years[year]) {
                tmp.push(courses[course])
                tmp = [...new Set(tmp)]
            }
            tmp_dict[years[year]] = tmp.sort(function(c1, c2) { return c1.code - c2.code })
            yearCourses.push(tmp_dict)
        }
    }
    yearCourses = [...new Set(yearCourses)]
    return { years, yearCourses }
}

function courseList(courses, years) {
    for (let course in courses) {
        years.push(courses[course]["year"])
        courses[course]["active_date_from"] = moment(new Date(courses[course]["active_date_from"])).locale('th').add(543, 'year').format('ll')
        courses[course]["active_date_to"] = moment(new Date(courses[course]["active_date_to"])).locale('th').add(543, 'year').format('ll')
        courses[course].program_name = changeProgramName(courses[course].program_code)
        courses[course].type_name = changeTypeName(courses[course])
    }
    return { courses, years }
}

function documentFileList(files, type, name) {
    let tmp = []
    for (let f in files) {
        if (files[f].type == type) {
            tmp.push(files[f])
        }
    }
    files = tmp
    name = type == 'PJ' ? "โครงงาน" : type == 'CP' ? "สหกิจศึกษา" : "ศึกษาอิสระ"
    return { files, name }
}

function fileUpload(event, files, file_name) {
    var file = Array.from(event.target.files)
    for (let f in file) {
        files.push(file[f])
    }
    files = [...new Set(files)]
    for (let f in files) {
        for (let j in files) {
            if (files[f].name == files[j].name && files[f] != files[j]) {
                files.splice(j, 1)
            }
        }
    }
    if (file.length > 1) {
        for (let f in file) {
            file_name.push(file[f].name)
        }
    } else if (file.length == 1 & file_name.length > 0) {
        file_name.push(file[0].name)
    } else if (file_name.length == 0) {
        file_name.push(file[0].name)
    }
    file_name = [...new Set(file_name)]

    return { files, file_name }
}

function checkCoadvisor(advisor) {
    for (let ad in advisor) {
        if (advisor[ad].role == 'C')
            return advisor[ad].name
    }
    return "-"
}

function checkCourse(project) {
    for (let i = 0; i <= project.course_details.length; i++) {
        let active_date_from = new Date(project.course_details[i].active_date_from)
        let active_date_to = new Date(project.course_details[i].active_date_to)
        if (active_date_from >= new Date() || active_date_to <= new Date()) {
            project.courses.splice(i, 1)
        }
    }
    let courseId = project.courses[0]
    return courseId
}

function academicYear(years) {
    let currentYear = new Date().getFullYear() + 544
    let startYear = 2556
    while (startYear <= currentYear) {
        years.push(startYear)
        startYear++
    }
    years.reverse()
    return years
}

export {
    changeProgramName,
    changeTypeName,
    enrollList,
    yearCourseSelect,
    courseList,
    documentFileList,
    fileUpload,
    checkCoadvisor,
    checkCourse,
    academicYear,
    changeProgramByType
}